import * as echarts from 'echarts'

// 设备趋势颜色
export const deviceTrendSeries = [
  {
    name: '在线设备',
    key: 'onlineTotal',
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: 'rgba(64, 221, 174, 0.22)',
        },
        {
          offset: 1,
          color: 'rgba(52,105,255,0)',
        },
      ]),
    },
    lineStyle: {
      color: 'rgba(64, 221, 174, 1)',
    },
    itemStyle: {
      color: 'rgba(64, 221, 174, 1)',
    },
  },
  {
    name: '未激活设备',
    key: 'noActivateTotal',
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: 'rgba(255,211,100, 0.22)',
        },
        {
          offset: 1,
          color: 'rgba(255,235,52,0)',
        },
      ]),
    },
    lineStyle: {
      color: 'rgba(255, 125, 0, 1)',
    },
    itemStyle: {
      color: 'rgba(255, 125, 0, 1)',
    },
  },
]

// 告警趋势颜色
export const warningTrendSeries: any = [
  {
    name: '信息',
    key: 'infoTotal',
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: 'rgba(129,226,255, 0.22)',
        },
        {
          offset: 1,
          color: 'rgba(52,255,243,0)',
        },
      ]),
    },
    lineStyle: {
      color: '#81E2FF',
    },
    itemStyle: {
      color: '#81E2FF',
    },
  },
  {
    name: '故障',
    key: 'errorTotal',
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: 'rgba(255,55,91, 0.22)',
        },
        {
          offset: 1,
          color: 'rgba(80,52,255,0)',
        },
      ]),
    },
    lineStyle: {
      color: '#FF375B',
    },
    itemStyle: {
      color: '#FF375B',
    },
  },
  {
    name: '告警',
    key: 'warnTotal',
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: 'rgba(255,172,50, 0.22)',
        },
        {
          offset: 1,
          color: 'rgba(52,255,243,0)',
        },
      ]),
    },
    lineStyle: {
      color: '#FFAC32',
    },
    itemStyle: {
      color: '#FFAC32',
    },
  },
]

// 趋势echarts配置
export const trendCommonConf: any = {
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    right: 100,
  },
  grid: {
    left: 80,
    right: 60,
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLabel: {
      inside: false,
      color: '#4E5969',
      align: 'center',
      margin: 33,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        type: 'solid',
        color: '#F2F3F5',
      },
    },
  },
  yAxis: {
    type: 'value',
    minInterval: 1,
    axisLabel: {
      color: '#4E5969',
      margin: 30,
    },
    splitLine: {
      lineStyle: {
        type: 'solid',
        color: '#F2F3F5',
      },
      show: true,
    },
  },
  series: [],
}

// 趋势echarts Series 配置
export const trendSeriesCommonConf = {
  type: 'line',
}
