import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import {
  queryDeviceLogType,
  queryProdtType,
  queryDevicefreqType,
  queryDeviceattrType,
  querySummarizeDeviceMsgType,
} from '@/api'
import { CODE_ENUM } from '@/common/enum'
import { transformEnum } from '@/utils'

export const useEnumsStore = defineStore('enums', () => {
  const deviceLogTypeEnum: any = ref([])
  const prodtTypeEnum: any = ref([])

  const getDeviceLogEnum = async () => {
    const res: any = await queryDeviceLogType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      const result = transformEnum(res?.data || [])
      deviceLogTypeEnum.value = result
      return Promise.resolve(result)
    }
  }

  const getProdtEnum = async () => {
    const res: any = await queryProdtType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      const result = transformEnum(res?.data || [])
      prodtTypeEnum.value = result
      return Promise.resolve(result)
    }
  }

  // 报表设备统计纬度
  const devicefreqEnum: any = ref([])
  const queryDevicefreqEnum = async () => {
    const res: any = await queryDevicefreqType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      const result = transformEnum(res?.data || [])
      devicefreqEnum.value = result
      return Promise.resolve(result)
    }
  }

  // 报表设备属性类型
  const deviceattrEnum: any = ref([])
  const queryDeviceattrEnum = async () => {
    const res: any = await queryDeviceattrType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      const result = transformEnum(res?.data || [])
      deviceattrEnum.value = result
      return Promise.resolve(result)
    }
  }

  // 首页消息上报图表类型
  const chartMsgEnum: any = ref([])
  const queryDeviceMsgType = async () => {
    const res: any = await querySummarizeDeviceMsgType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      const result = transformEnum(res?.data || [])
      chartMsgEnum.value = result
      return Promise.resolve(result)
    }
  }

  return {
    deviceLogTypeEnum,
    prodtTypeEnum,
    getDeviceLogEnum,
    getProdtEnum,
    devicefreqEnum,
    queryDevicefreqEnum,
    deviceattrEnum,
    queryDeviceattrEnum,
    chartMsgEnum,
    queryDeviceMsgType,
  }
})
